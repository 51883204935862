import React from "react";
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaReact,
  FaNodeJs,
  FaGithub,
  FaPython,
  FaDatabase,
  FaLeaf,
  FaFontAwesome,
  FaLinux,
} from "react-icons/fa";

export default function Technologies() {
  return (
    <article className="landingTech">
      <h2>Technologies</h2>

      {/* div to contain a list of tech skills */}
      <div className="technologies">
        <div className="techType">
          <h4> Skills </h4>
          <div className="cardContent">
            <div className="tech">
              <FaHtml5 className="icon" />
              <p>HTML</p>
            </div>

            <div className="tech">
              <FaCss3Alt className="icon" />
              <p>CSS</p>
            </div>

            <div className="tech">
              <FaJsSquare className="icon" />
              <p>JavaScript</p>
            </div>

            <div className="tech">
              <FaReact className="icon" />
              <p>React</p>
            </div>

            <div className="tech">
              <FaNodeJs className="icon" />
              <p>ExpressJs</p>
            </div>

            <div className="tech">
              <FaGithub className="icon" />
              <p>Git/GitHub</p>
            </div>

            <div className="tech">
              <FaPython className="icon" />
              <p>Python</p>
            </div>

            <div className="tech">
              <FaFontAwesome className="icon" />
              <p>MASM</p>
            </div>

            <div className="tech">
              <FaDatabase className="icon" />
              <p>SQL</p>
            </div>

            <div className="tech">
              <FaLeaf className="icon" />
              <p>MongoDb</p>
            </div>

            <div className="tech">
              <span className="icon">
                <strong>C</strong>
              </span>
              <p>c lang</p>
            </div>

            <div className="tech">
              <FaLinux className="icon" />
              <p>Linux</p>
            </div>
          </div>
        </div>

        <div className="techType">
          <h4>Interests</h4>
          <div className="cardContent">
            <p>
              I was introduced to programming more than a decade ago when I decided to use an Arduino to collect data for a lab I contributed to at U.C. Berkeley. Since then, I've used computers to put together a number of projects from building minecraft servers to studying the relationship between parking tickets and socioeconomic status in Oakland CA. Those projects taught me that even a bit of programming could bring a lot of utility to almost any project.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}
