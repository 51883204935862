import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import axios from 'axios';
import {FaGithub} from "react-icons/fa";
import {Card, CardHeader, CardBody, Heading, Stack, Box, Text, Button, useToast, color, background} from '@chakra-ui/react';
import UpdateProject from './UpdateProject';

const ProjectCard = ({project, isAuthor, setUpdated, updated}) => { 
  const [updateClicked, setUpdateClicked] = useState(false);
  const [user, setUser]                   = useState({});
  const toast = useToast();
    
  useEffect(()=>{
    async function loadUser() {
      const userObj = JSON.parse(localStorage.getItem("userInfo"));
      setUser(userObj);
    // set the user to the user object stored in the browser
      }
    loadUser();
    }, []);

    const deleteHandler = async()=>{
        if (!project._id){
            toast({
                title: "This Project is missing an id",
                status: "warning",
                duration: 4500,
                isClosable: true,
                position: "bottom",
                });
                return;
        }
        try {
          const config = {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            data: {
              _id: project._id,
            },
          };
          await axios.delete(`/api/projects`, config)
          setUpdated(!updated)
          toast({
                title: `${project.title} was deleted`,
                status: "success",
                duration: 4500,
                isClosable: true,
                position: "bottom",
                });
        } catch (error) {
          console.error(error)
        }
      }
    return (
      <Card
        className="card"
        style={{ color: "#f1f5f9", background: "#475569" }}
      >
        {isAuthor && (
          <Button background="#475569" onClick={deleteHandler}>
            Delete
          </Button>
        )}
        {isAuthor && (
          <Button
            background="#475569"
            onClick={() => setUpdateClicked(!updateClicked)}
          >
            Update
          </Button>
        )}
        {updateClicked && (
          <UpdateProject
            project={project}
            updated={updated}
            setUpdated={setUpdated}
          />
        )}
        {!updateClicked && (
          <>
            <CardHeader>
              <Heading style={{ height: "3rem" }}>{project.title}</Heading>
            </CardHeader>
            <CardBody>
              <div className="youtube">
                <YouTube
                  videoId={project.videoId}
                  opts={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
              </div>
              <Stack>
                <Text className="card-text">{project.description}</Text>
                <Box display="flex" alignItems="center">
                  <div className="tech">
                    <a href={project.github}>
                      <div className="tech">
                        <FaGithub />
                        <p>Github</p>
                      </div>
                    </a>
                  </div>
                  {project.projectLink !== "none" && (
                    <div className="tech">
                      <a href={project.projectLink}>Project Link</a>
                    </div>
                  )}
                </Box>
              </Stack>
            </CardBody>
          </>
        )}
      </Card>
    );
}

export default ProjectCard