import React from "react";
import profile from "../../images/profile.png";
export default function Landing() {
  return (
    <article className="landing" alt="profile picture of Sami Syed">
      <div className="about-text">
        <h2>About This Blog</h2>
        <p>
          Hi, my name is Sami. I put this blog together from scratch as a way to
          practice full stack development and keep track of some of the projects
          that I've worked on. Feel free to explore the site, and
          message me with any questions you have.
        </p>
      </div>
      <img src={profile} />
    </article>
  );
}
